import { CheckBox } from '@isdd/idsk-ui-kit/checkbox/CheckBox'
import { Button, ButtonLink, Filter } from '@isdd/idsk-ui-kit/index'
import { PaginatorWrapper } from '@isdd/idsk-ui-kit/paginatorWrapper/PaginatorWrapper'
import { Table, TableHandle } from '@isdd/idsk-ui-kit/table/Table'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import { DMS_DOWNLOAD_FILE } from '@isdd/metais-common/api/constants'
import { ApiError, ConfigurationItemUi, getReadCiNeighboursQueryKey, NeighbourSetUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { DOCUMENTS, INVALIDATED } from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { IBulkActionResult, useBulkAction } from '@isdd/metais-common/hooks/useBulkAction'
import { useGetFileSize } from '@isdd/metais-common/hooks/useGetFileSize'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import {
    ActionsOverTable,
    BulkPopup,
    DeleteFileBulkModal,
    FileHistoryModal,
    InvalidateBulkModal,
    MutationFeedback,
    ProjectUploadFileModal,
    QueryFeedback,
    ReInvalidateBulkModal,
    UpdateFileModal,
} from '@isdd/metais-common/index'
import styles from '@isdd/metais-common/components/actions-over-table/single-actions-popup/file-history/styles.module.scss'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQueryClient } from '@tanstack/react-query'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMutationErrors } from '@isdd/metais-common/components/actions-over-table/bulk-actions-popup/BulkList'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'
import { DocumentsActions } from '@isdd/metais-common/hooks/permissions/useProjectDocumentsPermissions'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import actionStyles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'

import { TableCols, defaultFilter } from '@/components/containers/DocumentListContainer'
import { downloadFile, isDocumentUpdatable, isDocumentsUpdatable } from '@/components/views/documents/utils'

interface DocumentsTable {
    ciData?: ConfigurationItemUi
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<NeighbourSetUi, ApiError>>
    data?: TableCols[]
    isLoading: boolean
    isError: boolean
    additionalColumns?: Array<ColumnDef<TableCols>>
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    namesData?: { login: string; fullName: string }[]
    rowSelection: RowSelection<TableCols>
}

interface TableMeta {
    isAnyModalOpened: boolean
}

export const DocumentsTable: React.FC<DocumentsTable> = ({
    data,
    additionalColumns,
    isLoading,
    isError,
    pagination,
    handleFilterChange,
    refetch,
    namesData,
    ciData,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()
    const isUserAdmin = user?.roles.includes('R_ADMIN')
    const isUserLogged = user !== null
    const isInvalidated = ciData?.metaAttributes?.state === INVALIDATED
    const focusTriggerRef = useRef<() => void>()
    const tableHandle = useRef<TableHandle>(null)

    const additionalColumnsNullsafe = additionalColumns ?? []

    const { errorMessage, isBulkLoading, handleInvalidate, handleReInvalidate, handleDeleteFile, handleUpdateFile } = useBulkAction()
    const [showInvalidate, setShowInvalidate] = useState<boolean>(false)
    const [showReInvalidate, setShowReInvalidate] = useState<boolean>(false)
    const [showDeleteFile, setShowDeleteFile] = useState<boolean>(false)
    const [bulkActionResult, setBulkActionResult] = useState<IBulkActionResult & IMutationErrors>()
    const [openAddModal, setOpenAddModal] = useState<ConfigurationItemUi>()

    const [invalidateSingle, setInvalidateSingle] = useState<ConfigurationItemUi>()
    const [deleteSingle, setDeleteSingle] = useState<ConfigurationItemUi>()
    const [updateFile, setUpdateFile] = useState<ConfigurationItemUi>()
    const [singleItemHistory, setSingleItemHistory] = useState<ConfigurationItemUi>()
    const queryClient = useQueryClient()
    const queryKey = getReadCiNeighboursQueryKey(ciData?.uuid ?? '', {})

    const [successfullyAdded, setSuccessfullyAdded] = useState<string[]>([])

    const handleCloseBulkModal = async (actionResult: IBulkActionResult & IMutationErrors, closeFunction: () => void) => {
        closeFunction()
        queryClient.invalidateQueries([queryKey[0]])
        await refetch()
        tableHandle.current?.refreshColumns()
        setBulkActionResult(actionResult)
        rowSelection.clearSelectedRows()
    }

    const [filesSizes, setFilesSizes] = useState<{ uuid: string; size: string; extension: string; metaComponent: string }[]>([])

    const { loadData } = useGetFileSize()

    const loadSizeInfo = useCallback(async () => {
        const sizes = await loadData(data?.map((d) => d.configurationItem?.uuid ?? '') ?? [])
        setFilesSizes(sizes)
    }, [data, loadData])

    useEffect(() => {
        if ((data?.length != 0 && filesSizes?.length == 0) || data?.length != filesSizes.length) {
            loadSizeInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filesSizes?.length])

    const tableMeta: TableMeta = {
        isAnyModalOpened: [updateFile, invalidateSingle, deleteSingle, singleItemHistory].some((item) => item !== undefined),
    }

    useEffect(() => {
        tableHandle.current?.refreshColumns()
    }, [isLoading])

    const columns: Array<ColumnDef<TableCols>> = [
        ...(isUserLogged
            ? [
                  {
                      accessorFn: (row) => row.selected,
                      header: ({ table }) => (
                          <div className="govuk-checkboxes govuk-checkboxes--small">
                              <CheckBox
                                  checked={table.getIsAllRowsSelected()}
                                  label=""
                                  name="checkbox"
                                  id="checkbox-all"
                                  onChange={table.getToggleAllRowsSelectedHandler()}
                                  aria-label={t('table.selectAllItems')}
                              />
                          </div>
                      ),
                      size: 45,
                      id: CHECKBOX_CELL,
                      cell: ({ row }) => (
                          <div className="govuk-checkboxes govuk-checkboxes--small">
                              <CheckBox
                                  label=""
                                  name="checkbox"
                                  id={`checkbox_${row.id}`}
                                  onChange={row.getToggleSelectedHandler()}
                                  checked={row.getIsSelected()}
                                  aria-label={t('table.selectItem', {
                                      itemName: row.original.configurationItem?.attributes?.Gen_Profil_nazov,
                                  })}
                              />
                          </div>
                      ),
                  } as ColumnDef<TableCols>,
              ]
            : []),
        {
            accessorFn: (row) => row?.configurationItem,
            header: t('documentsTab.table.name'),
            id: 'documentsTab.table.name',
            size: 200,
            meta: {
                getCellContext: (ctx: CellContext<ConfigurationItemUi, unknown>) =>
                    (ctx?.getValue?.() as ConfigurationItemUi).attributes?.Gen_Profil_nazo,
            },
            cell: (row) => {
                const ci = row.getValue() as ConfigurationItemUi
                return (
                    <>
                        <a href={`${DMS_DOWNLOAD_FILE}${ci?.uuid}`}>
                            {ci?.attributes?.Gen_Profil_nazov as string}{' '}
                            {filesSizes.find((f) => f.uuid == row.row.original.configurationItem?.uuid)?.metaComponent ?? ''}
                        </a>
                    </>
                )
            },
        },
        {
            accessorFn: (row) => row?.configurationItem?.attributes?.Gen_Profil_poznamka,
            header: t('documentsTab.table.note'),
            id: 'documentsTab.table.note',
            size: 200,
            meta: {
                getCellContext: (ctx: CellContext<ConfigurationItemUi, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => row.getValue() as string,
        },
        {
            accessorFn: (row) => row?.configurationItem?.metaAttributes?.state,
            header: t('documentsTab.table.evidenceStatus'),
            id: 'documentsTab.table.evidenceStatus',
            size: 100,
            meta: {
                getCellContext: (ctx: CellContext<ConfigurationItemUi, unknown>) => t(`metaAttributes.state.${ctx.getValue()}`),
            },
            cell: (row) => t(`metaAttributes.state.${row.getValue()}`) as string,
        },
        {
            accessorFn: (row) => row?.configurationItem?.metaAttributes?.createdAt,
            header: t('documentsTab.table.createdAt'),
            id: 'documentsTab.table.createdAt',
            size: 100,
            cell: (ctx) => t('dateTime', { date: ctx.getValue() as string }),
        },
        ...(isUserLogged
            ? [
                  {
                      accessorFn: (row) => row?.configurationItem?.metaAttributes?.createdBy,
                      header: t('documentsTab.table.createdBy'),
                      id: 'documentsTab.table.createdBy',
                      size: 100,
                      cell: (row) => namesData?.find((item) => item.login == (row.getValue() as string))?.fullName,
                  } as ColumnDef<TableCols>,
              ]
            : []),
        {
            accessorFn: (row) => row?.configurationItem?.metaAttributes?.lastModifiedAt,
            header: t('documentsTab.table.lastModifiedAt'),
            id: 'documentsTab.table.lastModifiedAt',
            size: 100,
            cell: (ctx) => t('dateTime', { date: ctx.getValue() as string }),
        },
        ...(isUserLogged
            ? [
                  {
                      accessorFn: (row) => row?.configurationItem?.metaAttributes?.lastModifiedBy,
                      header: t('documentsTab.table.lastModifiedBy'),
                      id: 'documentsTab.table.lastModifiedBy',
                      size: 100,
                      cell: (row) => namesData?.find((item) => item.login == (row.getValue() as string))?.fullName,
                  } as ColumnDef<TableCols>,
              ]
            : []),
        {
            header: t('fileHistory.contentLength'),
            id: 'contentLength',
            accessorKey: 'contentLength',
            cell: (row) => {
                return filesSizes.find((f) => f.uuid == row.row.original.configurationItem?.uuid)?.size
            },
        },
        {
            accessorKey: 'bulkActions',
            header: t('documentsTab.table.actions'),
            id: 'bulkActions',
            size: 130,
            cell: (ctx) => {
                return (
                    <BulkPopup
                        label={t('actionOverTable.options.title')}
                        manuallyHandledFocusAfterClose
                        onClose={() => {
                            const meta = ctx.table.options.meta as TableMeta
                            if (!meta.isAnyModalOpened) {
                                focusTriggerRef.current?.()
                            }
                        }}
                        onOpen={(focusTriggerEl) => {
                            focusTriggerRef.current = focusTriggerEl
                        }}
                        items={() => [
                            <ButtonLink
                                key={'buttonDownload'}
                                inPopup
                                label={
                                    <>
                                        {t('actionOverTable.options.download')}
                                        {filesSizes.find((f) => f.uuid == ctx.row.original.configurationItem?.uuid)?.metaComponent ?? ''}
                                    </>
                                }
                                onClick={() => {
                                    const item = data ? data[ctx.row.index] : {}
                                    downloadFile(
                                        `${DMS_DOWNLOAD_FILE}${item.configurationItem?.uuid}`,
                                        item.configurationItem?.attributes?.Gen_Profil_nazov as string,
                                    )
                                }}
                            />,
                            <Can key={'buttonUpdateCan'} I={DocumentsActions.HANDLE} a={DOCUMENTS}>
                                <ButtonLink
                                    className={actionStyles.buttonLinkWithIcon}
                                    inPopup
                                    key={'buttonUpdate'}
                                    label={t('actionOverTable.options.update')}
                                    disabled={!isUserLogged}
                                    onClick={() => {
                                        if (data !== undefined) {
                                            const item = data[ctx.row.index]

                                            if (item !== undefined) {
                                                setUpdateFile(item.configurationItem)
                                            }
                                            if (updateFile !== undefined) handleUpdateFile([updateFile ?? {}], () => setUpdateFile(undefined), open)
                                        }
                                    }}
                                    hidden={!isUserLogged}
                                    aria={{ 'aria-haspopup': 'dialog' }}
                                />
                            </Can>,
                            <Can key={'buttonInvalidateCan'} I={DocumentsActions.HANDLE} a={DOCUMENTS}>
                                <ButtonLink
                                    className={actionStyles.buttonLinkWithIcon}
                                    inPopup
                                    key={'buttonInvalidate'}
                                    label={t('actionOverTable.options.invalidate')}
                                    disabled={!isUserLogged}
                                    onClick={() => {
                                        if (data !== undefined) {
                                            const item = data[ctx.row.index]
                                            if (item.configurationItem !== undefined) {
                                                setInvalidateSingle(item.configurationItem)
                                            }
                                            if (invalidateSingle !== undefined)
                                                handleInvalidate(
                                                    [invalidateSingle],
                                                    () => setInvalidateSingle(undefined),
                                                    open,
                                                    isDocumentUpdatable(item),
                                                )
                                        }
                                    }}
                                    hidden={!isUserLogged}
                                    aria={{ 'aria-haspopup': 'dialog' }}
                                />
                            </Can>,
                            <Can key={'buttonDeleteCan'} I={DocumentsActions.DELETE} a={DOCUMENTS}>
                                <ButtonLink
                                    className={actionStyles.buttonLinkWithIcon}
                                    inPopup
                                    key={'buttonDelete'}
                                    label={t('actionOverTable.options.delete')}
                                    disabled={!isUserAdmin}
                                    onClick={() => {
                                        if (data !== undefined) {
                                            const item = data[ctx.row.index]
                                            if (item.configurationItem !== undefined) {
                                                setDeleteSingle(item.configurationItem)
                                            }
                                            if (deleteSingle !== undefined)
                                                handleDeleteFile([deleteSingle], () => setDeleteSingle(undefined), open, isDocumentUpdatable(item))
                                        }
                                    }}
                                    hidden={!isUserLogged}
                                    aria={{ 'aria-haspopup': 'dialog' }}
                                />
                            </Can>,
                            <Can key={'buttonHistoryCan'} I={DocumentsActions.HANDLE} a={DOCUMENTS}>
                                <ButtonLink
                                    className={actionStyles.buttonLinkWithIcon}
                                    inPopup
                                    key={'buttonHistory'}
                                    label={t('actionOverTable.options.history')}
                                    onClick={() => {
                                        if (data !== undefined) {
                                            const item = data[ctx.row.index]
                                            if (item.configurationItem !== undefined) {
                                                setSingleItemHistory(item.configurationItem)
                                            }
                                        }
                                    }}
                                    aria={{ 'aria-haspopup': 'dialog' }}
                                />
                            </Can>,
                        ]}
                    />
                )
            },
        },
        ...additionalColumnsNullsafe,
    ]

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        if (bulkActionResult?.isError || bulkActionResult?.isSuccess) {
            scrollToMutationFeedback()
        }
    }, [bulkActionResult, scrollToMutationFeedback])

    return (
        <QueryFeedback loading={isLoading || isBulkLoading} error={isError} indicatorProps={{ layer: 'parent' }} withChildren>
            <div ref={wrapperRef}>
                <MutationFeedback
                    error={bulkActionResult?.isError || (bulkActionResult?.isError && bulkActionResult?.additionalInfo?.action !== 'addedDocuments')}
                    errorMessage={bulkActionResult?.errorMessage}
                    mutationProcessingError={bulkActionResult?.isProcessedError}
                    success={bulkActionResult?.isSuccess && bulkActionResult?.additionalInfo?.action !== 'addedDocuments'}
                    successMessage={bulkActionResult?.successMessage + successfullyAdded.join(',')}
                    onMessageClose={() => setBulkActionResult(undefined)}
                />
            </div>
            <div ref={wrapperRef}>
                <MutationFeedback
                    success={bulkActionResult?.isSuccess && bulkActionResult?.additionalInfo?.action === 'addedDocuments'}
                    successMessage={t(`addFile${successfullyAdded.length > 1 ? 's' : ''}Success`, {
                        docs: successfullyAdded.join(', '),
                    })}
                    onMessageClose={() => setBulkActionResult(undefined)}
                />
            </div>

            <Filter form={() => <></>} defaultFilterValues={defaultFilter} onlySearch rowSelection={rowSelection} />
            <ActionsOverTable
                pagination={pagination}
                handleFilterChange={handleFilterChange}
                entityName={DOCUMENTS}
                hiddenButtons={{ SELECT_COLUMNS: true }}
                createButton={
                    <Button
                        disabled={isInvalidated}
                        label={t('documentsTab.addNewDocument')}
                        onClick={() => setOpenAddModal({})}
                        className={styles.marginBottom0}
                    />
                }
                selectedRowsCount={rowSelection.count}
                bulkPopup={({ selectedRowsCount }) => (
                    <Tooltip
                        descriptionElement={errorMessage}
                        position={'top center'}
                        tooltipContent={(open) => (
                            <BulkPopup
                                checkedRowItems={selectedRowsCount}
                                items={() => [
                                    <Can key={'buttonValidateItemsCan'} I={DocumentsActions.HANDLE} a={DOCUMENTS}>
                                        <ButtonLink
                                            className={actionStyles.buttonLinkWithIcon}
                                            inPopup
                                            key={'buttonValidateItems'}
                                            label={t('actionOverTable.validateItems')}
                                            disabled={!isUserLogged}
                                            onClick={() => {
                                                handleReInvalidate(
                                                    rowSelection.list.flatMap((item) => item.configurationItem ?? {}),
                                                    () => setShowReInvalidate(true),
                                                    open,
                                                )
                                            }}
                                            aria={{ 'aria-haspopup': 'dialog' }}
                                        />
                                    </Can>,
                                    <Can key={'buttonInvalidateItemsCan'} I={DocumentsActions.HANDLE} a={DOCUMENTS}>
                                        <ButtonLink
                                            className={actionStyles.buttonLinkWithIcon}
                                            inPopup
                                            key={'buttonInvalidateItems'}
                                            label={t('actionOverTable.invalidateItems')}
                                            disabled={!isUserLogged}
                                            onClick={() => {
                                                handleInvalidate(
                                                    rowSelection.list.flatMap((item) => item.configurationItem ?? {}),
                                                    () => setShowInvalidate(true),
                                                    open,
                                                    isDocumentsUpdatable(rowSelection.list),
                                                )
                                            }}
                                            aria={{ 'aria-haspopup': 'dialog' }}
                                        />
                                    </Can>,
                                    <Can key={'buttonDeleteItemsCan'} I={DocumentsActions.DELETE} a={DOCUMENTS}>
                                        <ButtonLink
                                            className={actionStyles.buttonLinkWithIcon}
                                            inPopup
                                            key={'buttonDeleteItems'}
                                            label={t('actionOverTable.deleteItems')}
                                            disabled={!isUserAdmin}
                                            onClick={() => {
                                                handleDeleteFile(
                                                    rowSelection.list.flatMap((item) => item.configurationItem ?? {}),
                                                    () => setShowDeleteFile(true),
                                                    open,
                                                    isDocumentsUpdatable(rowSelection.list),
                                                )
                                            }}
                                            aria={{ 'aria-haspopup': 'dialog' }}
                                        />
                                    </Can>,
                                ]}
                            />
                        )}
                    />
                )}
            />
            <InvalidateBulkModal
                items={rowSelection.list.flatMap((item) => item.configurationItem ?? {})}
                open={showInvalidate}
                multiple
                onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setShowInvalidate(false))}
                onClose={() => setShowInvalidate(false)}
            />
            <ReInvalidateBulkModal
                items={rowSelection.list.flatMap((item) => item.configurationItem ?? {})}
                relations={rowSelection.list.flatMap((item) => item.relationship ?? {})}
                open={showReInvalidate}
                multiple
                onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setShowInvalidate(false))}
                onClose={() => setShowReInvalidate(false)}
            />

            <DeleteFileBulkModal
                items={rowSelection.list.flatMap((item) => item.configurationItem ?? {})}
                open={showDeleteFile}
                onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setShowDeleteFile(false))}
                onClose={() => setShowDeleteFile(false)}
            />
            {/* Single items */}
            {invalidateSingle && (
                <InvalidateBulkModal
                    items={[invalidateSingle]}
                    open
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setInvalidateSingle(undefined))}
                    onClose={() => setInvalidateSingle(undefined)}
                />
            )}
            {deleteSingle && (
                <DeleteFileBulkModal
                    items={[deleteSingle]}
                    open
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setDeleteSingle(undefined))}
                    onClose={() => setDeleteSingle(undefined)}
                />
            )}
            {updateFile && (
                <UpdateFileModal
                    item={updateFile}
                    open={!!updateFile}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setUpdateFile(undefined))}
                    onClose={() => setUpdateFile(undefined)}
                />
            )}
            {openAddModal && (
                <ProjectUploadFileModal
                    isCi
                    project={ciData}
                    docNumber={String(data?.length) ?? '0'}
                    item={openAddModal}
                    open
                    onClose={() => setOpenAddModal(undefined)}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setOpenAddModal(undefined))}
                    setSuccessfullyAdded={setSuccessfullyAdded}
                />
            )}
            {singleItemHistory && <FileHistoryModal item={singleItemHistory} onClose={() => setSingleItemHistory(undefined)} />}
            <Table<TableCols>
                handleRef={tableHandle}
                rowSelection={rowSelection.rowSelection}
                onRowSelectionChange={rowSelection.getOnRowSelectionChange({
                    pageNumber: pagination.pageNumber,
                    mapper: (item) => data?.find((d) => d.configurationItem?.uuid === item) ?? {},
                })}
                columns={columns}
                data={data}
                getRowId={(originalRow) => `${originalRow.configurationItem?.uuid}`}
                tableMeta={tableMeta}
            />
            <PaginatorWrapper {...pagination} handlePageChange={handleFilterChange} />
        </QueryFeedback>
    )
}
