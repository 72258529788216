import { RelationshipType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { AbilityContextWithFeedback, useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { useRelationPermissions } from '@isdd/metais-common/hooks/permissions/useRelationPermissions'

interface iPermissionWrapper {
    children: JSX.Element
    relationTypeData: RelationshipType | undefined
}

export const RelationPermissionsWrapper = ({ children, relationTypeData }: iPermissionWrapper) => {
    const ability = useAbilityContext()
    useRelationPermissions(relationTypeData)
    return <AbilityContextWithFeedback.Provider value={{ ability }}>{children}</AbilityContextWithFeedback.Provider>
}
