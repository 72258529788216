import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { AbilityContextWithFeedback, useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { useProjectDocumentsPermissions } from '@isdd/metais-common/hooks/permissions/useProjectDocumentsPermissions'

interface IWrapper {
    children: JSX.Element
    projectData?: ConfigurationItemUi
    projectRoles: string[]
}

export const ProjectDocumentsPermissionsWrapper = ({ children, projectData, projectRoles }: IWrapper) => {
    const ability = useAbilityContext()
    useProjectDocumentsPermissions(projectData, projectRoles)
    return <AbilityContextWithFeedback.Provider value={{ ability }}>{children}</AbilityContextWithFeedback.Provider>
}
