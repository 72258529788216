import React, { useEffect, useState } from 'react'
import { ButtonPopup, Filter, Input, TextHeading } from '@isdd/idsk-ui-kit'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { QueryFeedback } from '@isdd/metais-common/components/query-feedback/QueryFeedback'
import { AddItemsButtonGroup } from '@isdd/metais-common/components/add-items-button-group/AddItemsButtonGroup'
import { ActionsOverTable } from '@isdd/metais-common/components/actions-over-table'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { DEFAULT_PAGESIZE_OPTIONS, ENTITY_ISVS, OPERATOR_SEPARATOR } from '@isdd/metais-common/constants'
import { useGetCiTypeConstraintsData } from '@isdd/metais-common/hooks/useGetCiTypeConstraintsData'
import {
    ConfigurationItemUi,
    IncidentRelationshipSetUi,
    useGetRoleParticipantBulk,
    useReadCiList1,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ColumnSort, IFilter, Pagination, SortType } from '@isdd/idsk-ui-kit/types'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'
import { ATTRIBUTE_NAME, BASE_PAGE_NUMBER, BASE_PAGE_SIZE, ModalButtons, RELATION_TYPE } from '@isdd/metais-common/index'
import { getSearchFields } from '@isdd/metais-common/componentHelpers/ci/filter'
import { mapFilterParamsToApi } from '@isdd/metais-common/componentHelpers'
import { FavoriteCiType, useGetDefaultColumns } from '@isdd/metais-common/api/generated/user-config-swagger'
import { DynamicFilterAttributes, ExtendedAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { includes } from 'lodash'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'

import { CiTableColumnsSelectForModal } from './CiTableColumnsSelectForModal'

import { ColumnsOutputDefinition, isMetaAttribute } from '@/componentHelpers/ci/ciTableHelpers'
import { CIFilterData } from '@/pages/ci/[entityName]/entity'
import { CiTable } from '@/components/ci-table/CiTable'

type Props = {
    ciType: string
    selectedItems: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null
    onSelectedSubmit: (val: ColumnsOutputDefinition[]) => void
    closeOnClick: () => void
    existingRelations: IncidentRelationshipSetUi | undefined
    relationType?: string
    ciItemData?: ConfigurationItemUi
}

export const CiListPageForModal: React.FC<Props> = ({
    ciType,
    selectedItems,
    onSelectedSubmit,
    closeOnClick,
    existingRelations,
    relationType,
    ciItemData,
}) => {
    const { t, i18n } = useTranslation()

    const defaultFilterValues: CIFilterData = {
        Gen_Profil_nazov: '',
        Gen_Profil_kod_metais: '',
    }

    const defaultPagination: Pagination = {
        pageNumber: BASE_PAGE_NUMBER,
        pageSize: BASE_PAGE_SIZE,
        dataLength: 0,
    }

    const [sort, setSort] = useState<ColumnSort[]>([{ orderBy: 'Gen_Profil_nazov', sortDirection: SortType.ASC }])
    const [pagination, setPagination] = useState(defaultPagination)
    const [customDisabledUuids, setCustomDisabledUuids] = useState<string[]>([])
    const [columnListData, setColumnListData] = useState<FavoriteCiType>()
    const [filterValues, setFilterValues] = useState<CIFilterData>(defaultFilterValues)
    const rowSelection = useRowSelection<ColumnsOutputDefinition>()

    const { data: defaultColumns, isLoading: isDefaultColumnsLoading, isError: isDefaultColumnsError } = useGetDefaultColumns(ciType)

    const {
        ciTypeData,
        attributes,
        attributeProfiles,
        constraintsData,
        unitsData,
        isError: isAttError,
        isLoading: isAttLoading,
    } = useAttributesHook(ciType)

    const {
        data: tableData,
        isLoading: isReadCiListLoading,
        isError: isReadCiListError,
    } = useReadCiList1({
        sortBy: sort[0].orderBy,
        sortType: sort[0].sortDirection,
        page: pagination.pageNumber,
        perpage: pagination.pageSize,
        filter: {
            type: [ciType],
            searchFields: getSearchFields(columnListData, ciTypeData ?? {}),
            fullTextSearch: filterValues.fullTextSearch || '',
            attributes: mapFilterParamsToApi(filterValues, {})
                .map((attr) => {
                    if (includes(attr.name, OPERATOR_SEPARATOR)) {
                        return { name: attr.name?.split(OPERATOR_SEPARATOR)[0], filterValue: attr.filterValue }
                    }
                    return attr
                })
                .filter((a) => !isMetaAttribute(a.name ?? '')),
            metaAttributes: filterValues.metaAttributeFilters,
        },
    })

    const ownerGids = new Set(tableData?.configurationItemSet?.map((item) => item.metaAttributes?.owner ?? ''))
    const {
        data: gestorsData,
        isLoading: isGestorsLoading,
        isError: isGestorsError,
    } = useGetRoleParticipantBulk({ gids: [...ownerGids] }, { query: { enabled: !!tableData && ownerGids && [...ownerGids]?.length > 0 } })

    const onColumnsSelect = (selectedColumns: Attribute[], selectedMetaColumns: Attribute[]) => {
        setColumnListData((prev) => {
            return { ...prev, attributes: selectedColumns, metaAttributes: selectedMetaColumns }
        })
    }

    const resetToDefault = () => {
        setColumnListData(defaultColumns)
    }

    useEffect(() => {
        setPagination({
            pageNumber: tableData?.pagination?.page ?? BASE_PAGE_NUMBER,
            pageSize: tableData?.pagination?.perPage ?? BASE_PAGE_SIZE,
            dataLength: tableData?.pagination?.totaltems ?? 0,
            totalPages: tableData?.pagination?.totalPages,
        })
    }, [tableData])

    useEffect(() => {
        setColumnListData(defaultColumns)
    }, [defaultColumns])

    const {
        isError: isCiTypeConstraintsError,
        isLoading: isCiTypeConstraintsLoading,
        uuidsToMatchedCiItemsMap,
    } = useGetCiTypeConstraintsData(ciTypeData, tableData?.configurationItemSet ?? [])

    const isLoading = [isAttLoading, isCiTypeConstraintsLoading, isReadCiListLoading, isDefaultColumnsLoading, isGestorsLoading].some((item) => item)
    const isError = [isAttError, isCiTypeConstraintsError, isReadCiListError, isDefaultColumnsError, isGestorsError].some((item) => item)

    useEffect(() => {
        if (selectedItems && Array.isArray(selectedItems)) {
            rowSelection.setRowSelection(
                selectedItems.reduce(
                    (acc: Record<string, ColumnsOutputDefinition>, item: ColumnsOutputDefinition) => ({
                        ...acc,
                        [item.uuid ?? '']: item,
                    }),
                    {},
                ),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems])

    const handleRelationItemsChange = () => {
        onSelectedSubmit(rowSelection.list)
        rowSelection.clearSelectedRows()
        closeOnClick()
    }

    const handleFilterChange = (filter: IFilter) => {
        setPagination((prevPag) => ({ ...prevPag, pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER, pageSize: filter.pageSize ?? BASE_PAGE_SIZE }))
        if (filter.sort) setSort(filter.sort)
        setFilterValues((prevFilter) => {
            return { ...prevFilter, ...filter }
        })
    }

    const resetFilter = () => {
        setFilterValues(defaultFilterValues)
    }

    const handleOnSubmit = (filter: IFilter) => {
        const mappedAttrs = mapFilterParamsToApi(filter)
        mappedAttrs.map((attr) => {
            return attr
        })
        setFilterValues((prevFilter) => {
            return { ...prevFilter, ...filter, metaAttributeFilters: {} }
        })
    }

    useEffect(() => {
        if (relationType === RELATION_TYPE.ISVS_patri_pod_ISVS && ciItemData?.type === ENTITY_ISVS) {
            setCustomDisabledUuids(
                tableData?.configurationItemSet
                    ?.filter((ci: ConfigurationItemUi) => {
                        return (
                            ci.attributes?.[ATTRIBUTE_NAME.EA_Profil_ISVS_modul_isvs] ===
                            ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_ISVS_modul_isvs]
                        )
                    })
                    .map((ci: ConfigurationItemUi) => ci.uuid ?? '') ?? [],
            )
        }
    }, [ciItemData?.attributes, ciItemData?.type, relationType, tableData?.configurationItemSet])

    const itemUuidsWithoutCheckboxes = [
        ...(existingRelations?.endRelationshipSet?.map((rel) => rel.startUuid ?? '') ?? []),
        ...(existingRelations?.startRelationshipSet?.map((rel) => rel.endUuid ?? '') ?? []),
        ...customDisabledUuids,
    ]

    return (
        <QueryFeedback loading={isLoading} withChildren>
            <FlexColumnReverseWrapper>
                <TextHeading size="L">{i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName}</TextHeading>
                <QueryFeedback loading={false} error={isError} errorProps={{ errorMessage: t('feedback.failedFetch') }} />
            </FlexColumnReverseWrapper>
            <Filter<CIFilterData, ColumnsOutputDefinition>
                rowSelection={rowSelection}
                handleOnSubmit={handleOnSubmit}
                customReset={() => resetFilter()}
                defaultFilterValues={defaultFilterValues}
                form={({ register, setValue }) => {
                    return (
                        <div>
                            <Input
                                id="name"
                                label={t(`filter.${ciType}.name`)}
                                placeholder={t(`filter.namePlaceholder`)}
                                {...register('Gen_Profil_nazov')}
                            />
                            <Input
                                id="metais-code"
                                label={t('filter.metaisCode.label')}
                                placeholder={ciTypeData?.codePrefix}
                                {...register('Gen_Profil_kod_metais')}
                            />
                            <DynamicFilterAttributes
                                setValue={setValue}
                                defaults={defaultFilterValues}
                                entityName={ciType}
                                attributes={attributes as ExtendedAttribute[]}
                                attributeProfiles={attributeProfiles}
                                constraintsData={constraintsData}
                            />
                        </div>
                    )
                }}
            />

            <ActionsOverTable
                pagination={pagination}
                handleFilterChange={handleFilterChange}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                entityName={ciTypeData?.name ?? ''}
                attributeProfiles={attributeProfiles ?? []}
                attributes={attributes ?? []}
                columnListData={columnListData}
                ciTypeData={ciTypeData}
                hiddenButtons={{ SELECT_COLUMNS: true }}
                isInModal
            >
                <AddItemsButtonGroup handleItemsChange={handleRelationItemsChange} onCancel={() => closeOnClick()} />

                <ButtonPopup
                    buttonLabel={t('actionOverTable.selectColumn')}
                    triggerAria={{ 'aria-label': t('actionOverTable.selectColumnAriaLabel') }}
                    buttonClassName="marginBottom0"
                    contentStyle={{ zIndex: 100001 }}
                    popupContent={(closePopup) => {
                        return (
                            <CiTableColumnsSelectForModal
                                columnListData={columnListData}
                                ciTypeData={ciTypeData}
                                onColumnsSelect={onColumnsSelect}
                                onClose={closePopup}
                                resetToDefault={resetToDefault}
                            />
                        )
                    }}
                />
            </ActionsOverTable>
            <CiTable
                data={{ columnListData, tableData, constraintsData, unitsData, entityStructure: ciTypeData, gestorsData }}
                handleFilterChange={handleFilterChange}
                pagination={pagination}
                sort={sort}
                rowSelection={rowSelection}
                isLoading={isLoading || isCiTypeConstraintsLoading}
                isError={isError || isCiTypeConstraintsError}
                uuidsToMatchedCiItemsMap={uuidsToMatchedCiItemsMap}
                itemUuidsWithoutCheckboxes={itemUuidsWithoutCheckboxes}
                linkToNewTab
                entityName={ciType}
                baseHref={`/ci/${ciType}`}
            />

            <ModalButtons
                submitButtonLabel={t('newRelation.addItems')}
                onSubmit={handleRelationItemsChange}
                closeButtonLabel={t('newRelation.cancel')}
                onClose={closeOnClick}
            />
        </QueryFeedback>
    )
}
