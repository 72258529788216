import { ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'
import { Attribute, CiCode } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'

import { HasResetState } from './CreateCiEntityForm'
import { findAttributeConstraint, getAttributeInputErrorMessage, getAttributeUnits, getHint } from './createEntityHelpers'

import { BlackListAttributeInput } from '@/components/attribute-input/BlackListAttributeInput'
import { AttributeInput } from '@/components/attribute-input/AttributeInput'

interface ISection {
    sectionId: string
    attributes: Attribute[]
    generatedEntityId: CiCode
    constraintsData: (EnumType | undefined)[]
    unitsData: EnumType | undefined
    defaultItemAttributeValues?: ConfigurationItemUiAttributes | undefined
    hasResetState: HasResetState
    setSectionError?: React.Dispatch<React.SetStateAction<{ [x: string]: boolean }>>
    updateCiItemId?: string
    sectionRoles: string[]
    selectedRole?: GidRoleData | null
    rolesForPO: GidRoleData[]
    maxTextInputLength?: number
    entityName?: string
}

export const CreateEntitySection: React.FC<ISection> = ({
    attributes,
    sectionId,
    constraintsData,
    generatedEntityId,
    unitsData,
    defaultItemAttributeValues,
    hasResetState,
    updateCiItemId,
    setSectionError,
    sectionRoles,
    rolesForPO,
    maxTextInputLength,
    entityName,
}) => {
    const { ability, isLoading } = useAbilityContextWithFeedback()

    const { register, formState, trigger, setValue, clearErrors, control, watch } = useFormContext()
    const { errors, isSubmitted } = formState

    const canEditSection = useMemo(() => {
        return sectionRoles.some((sectionRole) => rolesForPO?.map((role) => role.roleName).includes(sectionRole))
    }, [sectionRoles, rolesForPO])
    const isUpdateSectionDisabled = !!updateCiItemId && !ability?.can(Actions.EDIT, `ci.${updateCiItemId}.attributeProfile.${sectionId}`)
    const isUpdate = !!updateCiItemId
    const isSectionError = Object.keys(errors).find((item) => attributes.find((attribute) => attribute.technicalName === item))

    useEffect(() => {
        setSectionError?.((prev) => ({ ...prev, [sectionId]: !!isSectionError }))
    }, [sectionId, isSectionError, setSectionError])

    useEffect(() => {
        watch()
    }, [watch])

    if (isLoading) {
        return <></>
    }

    const readOnlyCreateBlackList: Record<string, boolean> = {
        [ATTRIBUTE_NAME.EA_Profil_Projekt_faza_projektu]: true,
        [ATTRIBUTE_NAME.EA_Profil_Projekt_status]: true,
    }

    return (
        <>
            {attributes?.map?.((attribute) => {
                const isMetodaRiadenia = ATTRIBUTE_NAME.EA_Profil_Projekt_metoda_riadenia_projektu === attribute.technicalName
                if (isMetodaRiadenia && !isUpdate) {
                    attribute.readOnly = false
                } else if (isMetodaRiadenia && isUpdate) {
                    attribute.readOnly = true
                }

                if (readOnlyCreateBlackList[attribute.technicalName ?? '']) {
                    return (
                        <BlackListAttributeInput
                            key={attribute.technicalName}
                            entityName={entityName ?? ''}
                            defaultValueFromCiItem={
                                defaultItemAttributeValues?.[attribute.technicalName ?? ''] as string | number | boolean | string[] | undefined
                            }
                            isUpdate={!!updateCiItemId}
                            attribute={attribute}
                            disabled={!canEditSection || isUpdateSectionDisabled}
                        />
                    )
                }

                return (
                    <React.Fragment key={attribute.technicalName}>
                        {!attribute.invisible && attribute.valid && (
                            <AttributeInput
                                control={control}
                                trigger={trigger}
                                setValue={setValue}
                                attribute={attribute}
                                constraints={findAttributeConstraint(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    //@ts-ignore
                                    attribute?.constraints?.map((item: AttributeConstraintEnumAllOf) => item.enumCode ?? '') ?? [],
                                    constraintsData,
                                )}
                                watch={watch}
                                clearErrors={clearErrors}
                                register={register}
                                error={getAttributeInputErrorMessage(attribute, errors)}
                                isSubmitted={isSubmitted}
                                hint={getHint(attribute, generatedEntityId)}
                                unitsData={attribute.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                defaultValueFromCiItem={
                                    defaultItemAttributeValues?.[attribute.technicalName ?? ''] as string | number | boolean | string[] | undefined
                                }
                                hasResetState={hasResetState}
                                disabled={!canEditSection || isUpdateSectionDisabled}
                                isUpdate={!!updateCiItemId}
                                maxLength={maxTextInputLength}
                                entityName={entityName}
                            />
                        )}
                    </React.Fragment>
                )
            })}
        </>
    )
}
