import { Button } from '@isdd/idsk-ui-kit/button/Button'
import { TextHeading } from '@isdd/idsk-ui-kit/index'
import { PageSizeSelect } from '@isdd/idsk-ui-kit/page-size-select/PageSizeSelect'
import { PaginatorWrapper } from '@isdd/idsk-ui-kit/paginatorWrapper/PaginatorWrapper'
import { Tabs } from '@isdd/idsk-ui-kit/tabs/Tabs'
import { IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import { BASE_PAGE_NUMBER, MutationFeedback, QueryFeedback, formatRelationAttributes } from '@isdd/metais-common'
import {
    ApiError,
    CiWithRelsResultUi,
    ConfigurationItemUi,
    ReadCiNeighboursWithAllRelsParams,
    RelationshipListUi,
    RelListFilterContainerUi,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { CAN_NOT_MANAGE_CI, ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE, ENTITY_KS, ENTITY_OBJEKT_EVIDENCIE } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { Actions, useCreateCiAbility } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRelationForCiPermissions } from '@isdd/metais-common/hooks/permissions/useRelationForCiPermissions'
import { shouldEntityNameBePO } from '@isdd/metais-common/componentHelpers/ci/entityNameHelpers'
import { UseMutateAsyncFunction } from '@tanstack/react-query'

import { CardColumnList } from './cards/CardColumnList'
import { RelationCard } from './cards/RelationCard'
import styles from './styles.module.scss'

import { IRelationsView, ITabsToShowRelAttributes } from '@/components/containers/RelationsListContainer'
import { CiRelationsWizard } from '@/components/wizards/CiRelationsWizard'
import { AtributObjektEvidencieFilterData } from '@/pages/ci/AtributObjektuEvidencie/entity'
import { ListWrapper } from '@/components/list-wrapper/ListWrapper'
import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import EvidenceObjectAttributeListContainer from '@/components/containers/evidence-object-attribute/EvidenceObjectAttributeListContainer'

interface NeighboursCardListProps {
    areTypesLoading: boolean
    isLoading: boolean
    isDerivedLoading: boolean
    isError: boolean
    data: IRelationsView['data']
    relationsList: CiWithRelsResultUi | undefined
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    setPageConfig: (value: React.SetStateAction<ReadCiNeighboursWithAllRelsParams>) => void
    setIsDerived: (value: React.SetStateAction<boolean>) => void
    ciTypeData: CiType | undefined
    hideButtons: boolean
    hidePageSizeSelect: boolean
    tabsToShowRelAttributes?: ITabsToShowRelAttributes[]
    name?: string
    derivedRelationItems?: ConfigurationItemUi[]
    loadKsPhaseAttributes?: UseMutateAsyncFunction<
        RelationshipListUi,
        ApiError,
        {
            data: RelListFilterContainerUi
        },
        unknown
    >
}

export const NeighboursCardList: React.FC<NeighboursCardListProps> = ({
    areTypesLoading,
    isLoading,
    isDerivedLoading,
    isError,
    relationsList,
    data,
    pagination,
    handleFilterChange,
    setPageConfig,
    setIsDerived,
    ciTypeData,
    hideButtons,
    hidePageSizeSelect,
    tabsToShowRelAttributes = [],
    name,
    derivedRelationItems,
    loadKsPhaseAttributes,
}) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {
        state: { user },
    } = useAuth()
    const isLoggedIn = !!user?.uuid
    const { entityId, entityName } = useGetEntityParamsFromUrl()

    const { owners, relationTypes } = data

    const { isActionSuccess } = useActionSuccess()

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    const ciAbility = useCreateCiAbility(ciTypeData?.technicalName ?? '', ciTypeData)
    const relationAbility = useRelationForCiPermissions(shouldEntityNameBePO(entityName ?? ''))

    const canCreateCi = ciAbility.can(Actions.CREATE, 'ci')
    const tabsKey = data.keysToDisplay.map((key) => key.count).reduce((count1, count2) => count1 + count2, 0)
    useEffect(() => {
        scrollToMutationFeedback(true)
    }, [isActionSuccess.value, scrollToMutationFeedback])

    const [selectedTab, setSelectedTab] = useState({ id: data?.keysToDisplay?.[0]?.technicalName })
    const disabledCreateCI = useMemo(() => {
        return CAN_NOT_MANAGE_CI.includes(selectedTab.id)
    }, [selectedTab.id])

    const loadPhases = async () => {
        if (loadKsPhaseAttributes) {
            return await loadKsPhaseAttributes({
                data: {
                    relFilter: {
                        type: ['KS_ma_FazaZivotnehoCyklu'],
                        metaAttributes: {
                            state: ['DRAFT'],
                        },
                        endCiTypeName: ['FazaZivotnehoCyklu'],
                        startCiUuid: relationsList?.ciWithRels?.map((ci) => ci.ci?.uuid ?? ''),
                    },
                },
            })
        }
    }
    return (
        <>
            <TextHeading size="L">{t('neighboursCardList.heading')}</TextHeading>
            <div ref={wrapperRef}>
                <MutationFeedback
                    success={isActionSuccess.value && isActionSuccess.additionalInfo?.type === 'relationCreated'}
                    successMessage={t('mutationFeedback.successfulRelationCreated')}
                />
            </div>
            <QueryFeedback loading={areTypesLoading} withChildren>
                {!areTypesLoading && (
                    <>
                        <CiRelationsWizard />
                        <Tabs
                            id="relWizardRef1"
                            key={tabsKey}
                            tabList={data.keysToDisplay.map((key) => ({
                                id: key?.technicalName,
                                title: (key.isDerived ? t('relationDetail.derived') : '') + key?.tabName,
                                meta: { isDerived: key.isDerived },
                                content:
                                    key.technicalName === ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE && entityName === ENTITY_OBJEKT_EVIDENCIE ? (
                                        <EvidenceObjectAttributeListContainer<AtributObjektEvidencieFilterData>
                                            entityName={ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE}
                                            defaultFilterValues={{
                                                Gen_Profil_nazov: '',
                                                Gen_Profil_kod_metais: '',
                                                'Relation--Objekt_evidencie_ma_atribut_evidencie': entityId,
                                            }}
                                            OEuuid={entityId}
                                            hideHeader
                                            ListComponent={(props) => (
                                                <>
                                                    <ListWrapper
                                                        {...props}
                                                        attributes={props.attributes}
                                                        baseHref={props.baseHref}
                                                        linkToNewTab
                                                        queryParamForCreate={`?OE=${entityId}`}
                                                    />
                                                </>
                                            )}
                                        />
                                    ) : (
                                        <QueryFeedback
                                            loading={(isLoading && !key.isDerived) || (isDerivedLoading && key.isDerived)}
                                            error={isError}
                                            errorProps={{ errorMessage: t('feedback.failedFetch') }}
                                            withChildren
                                        >
                                            <div
                                                className={classNames([styles.tableActionsWrapper], {
                                                    [styles.flexEnd]: !isLoggedIn || hideButtons,
                                                })}
                                            >
                                                {isLoggedIn && key.isDerived && <p className={styles.maxWidth}>{t('relationDetail.derivedInfo')}</p>}
                                                {isLoggedIn && !key.isDerived && !hideButtons && (
                                                    <div className={styles.buttonGroup}>
                                                        <Button
                                                            id="relWizardRef2"
                                                            className={'marginBottom0'}
                                                            label={t('neighboursCardList.buttonAddNewRelation')}
                                                            variant="secondary"
                                                            disabled={
                                                                !relationAbility?.can(Actions.CREATE, `ci.create.newRelation.${key.technicalName}`)
                                                            }
                                                            onClick={() =>
                                                                navigate(`new-relation/${key.technicalName}`, { state: { from: location } })
                                                            }
                                                        />
                                                        <Button
                                                            id="relWizardRef3"
                                                            className={'marginBottom0'}
                                                            onClick={() => navigate(`new-ci/${key.technicalName}`, { state: { from: location } })}
                                                            label={t('neighboursCardList.buttonAddNewRelationCard')}
                                                            variant="secondary"
                                                            disabled={
                                                                !canCreateCi ||
                                                                disabledCreateCI ||
                                                                !relationAbility.can(
                                                                    Actions.CREATE,
                                                                    `ci.create.newRelationAndCi.${key.technicalName}`,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {!hidePageSizeSelect && (
                                                    <PageSizeSelect
                                                        className={styles.perPageSelectWrapper}
                                                        handlePagingSelect={(page) => {
                                                            setPageConfig((pageConfig) => {
                                                                return {
                                                                    ...pageConfig,
                                                                    page: BASE_PAGE_NUMBER,
                                                                    perPage: Number(page),
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <CardColumnList>
                                                {relationsList?.ciWithRels?.map((ciWithRel) => {
                                                    const formatedCiWithRel = formatRelationAttributes({
                                                        ciWithRel,
                                                        relationTypes,
                                                        owners,
                                                        lng: i18n,
                                                    })

                                                    const relAttributes = tabsToShowRelAttributes.find((tab) => tab.entityName === key.technicalName)
                                                    return (
                                                        <RelationCard
                                                            loadPhases={key.technicalName == ENTITY_KS ? loadPhases : undefined}
                                                            entityName={name}
                                                            entityId={entityId}
                                                            isDerived={key.isDerived}
                                                            derivedRelationItems={derivedRelationItems}
                                                            {...formatedCiWithRel}
                                                            key={formatedCiWithRel?.codeMetaIS}
                                                            showRelAttributes={!!relAttributes}
                                                            attributesToDisplay={relAttributes?.attributes}
                                                        />
                                                    )
                                                })}
                                            </CardColumnList>
                                            <PaginatorWrapper
                                                pageNumber={pagination.pageNumber}
                                                pageSize={pagination.pageSize}
                                                dataLength={pagination.dataLength}
                                                handlePageChange={handleFilterChange}
                                            />
                                        </QueryFeedback>
                                    ),
                            }))}
                            onSelect={(selected) => {
                                setSelectedTab(selected)
                                setIsDerived(selected.meta?.isDerived ? true : false)
                                setPageConfig((pageConfig) => {
                                    if (!selected.meta?.isDerived) {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { relTypes, ...rest } = pageConfig
                                        return { ...rest, ciTypes: [selected.id], page: 1 }
                                    } else {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { ciTypes, ...rest } = pageConfig
                                        return { ...rest, relTypes: [selected.id], page: 1 }
                                    }
                                })
                            }}
                        />
                    </>
                )}
            </QueryFeedback>
        </>
    )
}
