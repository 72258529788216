import { IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import {
    ApiError,
    ReadCiNeighboursWithAllRelsParams,
    RelationshipListUi,
    RelListFilterContainerUi,
    RoleParticipantUI,
    useReadCiList1,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { RelationshipTypePreview, useListRelationshipTypes } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { mapFilterToNeighboursWithAllRelsApi } from '@isdd/metais-common/componentHelpers'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, RELATIONSHIP_TYPES_QUERY_KEY } from '@isdd/metais-common/constants'
import { useUserPreferences } from '@isdd/metais-common/contexts/userPreferences/userPreferencesContext'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { useEntityRelationsDataList } from '@isdd/metais-common/hooks/useEntityRelationsDataList'
import { IKeyToDisplay, useEntityRelationsTypesCount } from '@isdd/metais-common/hooks/useEntityRelationsTypesCount'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getRelationsSorter } from '@/componentHelpers/ci/ciRelationsSortConfig'
import { NeighboursCardList } from '@/components/entities/NeighboursCardList'

export interface IRelationsView {
    isLoading: boolean
    isError: boolean
    data: {
        owners?: void | RoleParticipantUI[] | undefined
        keysToDisplay: IKeyToDisplay[]
        relationTypes: RelationshipTypePreview[]
    }
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    setPageConfig: React.Dispatch<SetStateAction<ReadCiNeighboursWithAllRelsParams>>
}

export interface ITabsToShowRelAttributes {
    entityName: string
    attributes: string[]
}

interface IRelationsListContainer {
    entityId: string
    technicalName: string
    entityName?: string
    showOnlyTabsWithRelations?: boolean
    hideButtons?: boolean
    hidePageSizeSelect?: boolean
    includeDeleted?: boolean
    tabsToShowRelAttributes?: ITabsToShowRelAttributes[]
    loadKsPhaseAttributes?: UseMutateAsyncFunction<
        RelationshipListUi,
        ApiError,
        {
            data: RelListFilterContainerUi
        },
        unknown
    >
}

export const RelationsListContainer: React.FC<IRelationsListContainer> = ({
    entityId,
    technicalName,
    showOnlyTabsWithRelations = false,
    hideButtons = false,
    hidePageSizeSelect = false,
    includeDeleted = false,
    tabsToShowRelAttributes = [],
    entityName,
    loadKsPhaseAttributes,
}) => {
    const { i18n } = useTranslation()

    const { data: ciTypeData } = useGetCiTypeWrapper(technicalName)
    const {
        isLoading: areTypesLoading,
        isError: areTypesError,
        keysToDisplay: keysToDisplayUnsorted,
    } = useEntityRelationsTypesCount(entityId, technicalName, includeDeleted)

    const keysToDisplay = useMemo(() => keysToDisplayUnsorted.sort(getRelationsSorter(technicalName)), [keysToDisplayUnsorted, technicalName])

    const { data: relationTypes } = useListRelationshipTypes({ filter: {} }, { query: { queryKey: [RELATIONSHIP_TYPES_QUERY_KEY, i18n.language] } })

    const { currentPreferences } = useUserPreferences()

    const defaultCiType = keysToDisplay?.[0]?.technicalName
    const defaultIsDerived = keysToDisplay?.[0]?.isDerived
    const defaultCiTypes: string[] = useMemo((): string[] => {
        return defaultCiType ? [defaultCiType] : []
    }, [defaultCiType])

    const defaultPageConfig: ReadCiNeighboursWithAllRelsParams = useMemo(() => {
        const invalidatedParams = includeDeleted ? ['DRAFT', 'INVALIDATED', 'DELETED'] : ['DRAFT', 'INVALIDATED']
        const state = currentPreferences.showInvalidatedItems ? invalidatedParams : ['DRAFT']
        if (!defaultIsDerived) {
            return {
                ciTypes: defaultCiTypes,
                page: BASE_PAGE_NUMBER,
                perPage: currentPreferences.defaultPerPage ? Number(currentPreferences.defaultPerPage) : BASE_PAGE_SIZE,
                state,
            }
        } else {
            return {
                relTypes: defaultCiTypes,
                page: BASE_PAGE_NUMBER,
                perPage: currentPreferences.defaultPerPage ? Number(currentPreferences.defaultPerPage) : BASE_PAGE_SIZE,
                state,
            }
        }
    }, [currentPreferences.defaultPerPage, currentPreferences.showInvalidatedItems, defaultCiTypes, defaultIsDerived, includeDeleted])

    const [pageConfig, setPageConfig] = useState<ReadCiNeighboursWithAllRelsParams>(defaultPageConfig)
    const [isDerived, setIsDerived] = useState<boolean>(false)
    const [derivedRelationsUuids, setDerivedRelationsUuids] = useState<Set<string>>()

    useEffect(() => {
        if (defaultCiType) {
            setIsDerived(defaultIsDerived)
            setPageConfig(defaultPageConfig)
        }
    }, [defaultCiType, defaultIsDerived, defaultPageConfig])

    const handleFilterChange = (filter: IFilter) => {
        setPageConfig(mapFilterToNeighboursWithAllRelsApi(pageConfig, filter))
    }

    const {
        isLoading: areRelationsLoading,
        isDerivedLoading: areDerivedLoading,
        isError: areRelationsError,
        relationsList,
        owners,
    } = useEntityRelationsDataList(entityId, pageConfig, isDerived)

    useEffect(() => {
        if (isDerived && relationsList && !areDerivedLoading) {
            const relationsUuids = new Set<string>()
            relationsList?.ciWithRels?.forEach((item) =>
                item.rels?.forEach((rel) => {
                    rel.startUuid && relationsUuids.add(rel.startUuid)
                    rel.endUuid && relationsUuids.add(rel.endUuid)
                }),
            )
            setDerivedRelationsUuids(relationsUuids)
        } else if (!isDerived) {
            setDerivedRelationsUuids(undefined)
        }
    }, [areDerivedLoading, isDerived, relationsList])

    const {
        data: derivedRelationItems,
        isLoading: isLoadingDerivedRelationItems,
        isError: isErrorDerivedRelationItems,
        fetchStatus: fetchStatusDerivedRelationItems,
    } = useReadCiList1(
        {
            filter: {
                uuid: Array.from(derivedRelationsUuids ?? []),
            },
        },
        { query: { enabled: isDerived && !!derivedRelationsUuids?.size } },
    )

    const pagination: Pagination = {
        pageNumber: pageConfig.page ?? 1,
        pageSize: pageConfig.perPage ?? 10,
        dataLength: relationsList?.pagination?.totaltems ?? 0,
        totalPages: relationsList?.pagination?.totalPages ?? 0,
    }

    const isError = areTypesError || areRelationsError

    const keysToDisplayOnlyWithRelations = keysToDisplay?.filter?.((item) => item.count)

    return (
        <NeighboursCardList
            loadKsPhaseAttributes={loadKsPhaseAttributes}
            tabsToShowRelAttributes={tabsToShowRelAttributes}
            areTypesLoading={areTypesLoading}
            isLoading={areRelationsLoading}
            isDerivedLoading={areDerivedLoading || (isLoadingDerivedRelationItems && fetchStatusDerivedRelationItems != 'idle')}
            isError={isError || isErrorDerivedRelationItems}
            relationsList={{ ...relationsList }}
            data={{
                owners,
                keysToDisplay: showOnlyTabsWithRelations ? keysToDisplayOnlyWithRelations : keysToDisplay ?? [],
                relationTypes: relationTypes?.results ?? [],
            }}
            name={entityName}
            pagination={pagination}
            handleFilterChange={handleFilterChange}
            setPageConfig={setPageConfig}
            setIsDerived={setIsDerived}
            ciTypeData={ciTypeData}
            hideButtons={hideButtons}
            derivedRelationItems={derivedRelationItems?.configurationItemSet}
            hidePageSizeSelect={hidePageSizeSelect}
        />
    )
}
