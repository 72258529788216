import React from 'react'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { DocumentsListContainer } from '@/components/containers/DocumentListContainer'
import { DocumentsTable } from '@/components/views/documents/DocumentsTable'
import { ProjectDocumentsPermissionsWrapper } from '@/components/permissions/ProjectDocumentsPermissionsWrapper'

const DocumentsListPage: React.FC = () => {
    const { entityId } = useGetEntityParamsFromUrl()

    return (
        <DocumentsListContainer
            configurationItemId={entityId}
            View={(props) => {
                return (
                    <ProjectDocumentsPermissionsWrapper projectData={props.ciData} projectRoles={props.ciTypeData?.roleList ?? []}>
                        <DocumentsTable {...props} />
                    </ProjectDocumentsPermissionsWrapper>
                )
            }}
        />
    )
}

export default DocumentsListPage
