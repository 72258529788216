import { intersection } from 'lodash'
import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { useEffect } from 'react'

import { useAbilityContext } from './useAbilityContext'
import { Actions } from './useUserAbility'

import { RelationshipType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'

export const useRelationPermissions = (relationTypeData: RelationshipType | undefined) => {
    const abilityContext = useAbilityContext()
    const {
        state: { user },
    } = useAuth()

    useEffect(() => {
        const canEditRelation = intersection(user?.roles, relationTypeData?.roleList).length > 0

        const { can, rules } = new AbilityBuilder(createMongoAbility)

        if (canEditRelation) can(Actions.EDIT, 'relation')

        abilityContext.update(rules)
    }, [abilityContext, relationTypeData?.roleList, user?.roles])

    return {}
}
