import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAddFavorite } from './useAddFavorite'
import { useBulkActionHelpers } from './useBulkActionHelpers'

import { ApiError, ConfigurationItemUi, getReadConfigurationItemQueryKey } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { getGetNextStatesQueryKey, useNextStateOverwritten, usePreviousState } from '@isdd/metais-common/api/generated/kris-swagger'
import { FollowedItemItemType } from '@isdd/metais-common/api/generated/user-config-swagger'
import { ATTRIBUTE_NAME, PROJECT_STATE_ENUM } from '@isdd/metais-common/index'

export interface IBulkActionResult {
    isSuccess: boolean
    isError: boolean
    successMessage?: string
    errorMessage?: string
    additionalInfo?: { [key: string]: string }
}

export const useBulkAction = (isRelation?: boolean) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const { bulkCheck, bulkRelationCheck, checkChangeOfOwner, ciInvalidFilter, hasOwnerRights } = useBulkActionHelpers()
    const { addFavoriteAsync } = useAddFavorite()

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isBulkLoading, setBulkLoading] = useState<boolean>(false)

    const { mutateAsync: confirmProjectMutate } = useNextStateOverwritten()
    const { mutateAsync: mutateReturnProject } = usePreviousState()

    const handleInvalidate = async (items: ConfigurationItemUi[], onSuccess: () => void, onError: () => void, isDocTypeValid?: boolean) => {
        setBulkLoading(true)
        const isValid = items.every((item) => !ciInvalidFilter(item))

        if (!isValid) {
            setBulkLoading(false)
            setErrorMessage(t('tooltip.rights.invalidSelectedList'))
            return onError()
        }

        if (isDocTypeValid !== undefined && !isDocTypeValid) {
            setErrorMessage(t('tooltip.rights.missingPermission'))
            setBulkLoading(false)
            return onError()
        }
        try {
            const hasRights = await hasOwnerRights(items)

            if (hasRights) {
                setErrorMessage(undefined)
                return onSuccess()
            } else {
                setBulkLoading(false)
                setErrorMessage(t('tooltip.rights.missingPermission'))
                return onError()
            }
        } catch (e) {
            setErrorMessage(t('tooltip.rights.notFoundPO'))
            return onError()
        } finally {
            setBulkLoading(false)
        }
    }

    const handleDeleteFile = async (items: ConfigurationItemUi[], onSuccess: () => void, onError: () => void, isDocTypeValid?: boolean) => {
        setBulkLoading(true)

        if (isDocTypeValid !== undefined && !isDocTypeValid) {
            setErrorMessage(t('tooltip.rights.missingPermission'))
            setBulkLoading(false)
            return onError()
        }
        try {
            const hasRights = await hasOwnerRights(items)

            if (hasRights) {
                setErrorMessage(undefined)
                return onSuccess()
            } else {
                setBulkLoading(false)
                setErrorMessage(t('tooltip.rights.missingPermission'))
                return onError()
            }
        } catch (e) {
            setErrorMessage(t('tooltip.rights.notFoundPO'))
            return onError()
        } finally {
            setBulkLoading(false)
        }
    }

    const handleUpdateFile = async (items: ConfigurationItemUi[], onSuccess: () => void, onError: () => void) => {
        setBulkLoading(true)
        try {
            const hasRights = await hasOwnerRights(items)

            if (hasRights) {
                setErrorMessage(undefined)
                return onSuccess()
            } else {
                setBulkLoading(false)
                setErrorMessage(t('tooltip.rights.missingPermission'))
                return onError()
            }
        } catch (e) {
            setErrorMessage(t('tooltip.rights.notFoundPO'))
            return onError()
        } finally {
            setBulkLoading(false)
        }
    }

    const handleReInvalidate = async (items: ConfigurationItemUi[], onSuccess: () => void, onError: () => void) => {
        setBulkLoading(true)
        const isValid = items.every((item) => ciInvalidFilter(item))
        if (!isValid) {
            setErrorMessage(t('tooltip.rights.validSelectedList'))
            setBulkLoading(false)
            return onError()
        }

        let canReInvalidate = undefined
        if (isRelation) {
            canReInvalidate = await bulkRelationCheck(items)
        } else {
            canReInvalidate = await bulkCheck(items)
        }

        setBulkLoading(false)
        if (canReInvalidate) {
            setErrorMessage(undefined)
            return onSuccess()
        } else return setErrorMessage(t('tooltip.rights.missingPermission'))
    }

    const handleChangeOwner = async (items: ConfigurationItemUi[], onSuccess: () => void, onError: () => void) => {
        setBulkLoading(true)
        const isValid = !items.every((item) => ciInvalidFilter(item))

        if (!isValid) {
            setErrorMessage(t('tooltip.rights.invalidSelectedList'))
            setBulkLoading(false)
            return onError()
        }

        const ownerGids = await checkChangeOfOwner(items)

        const hasRights = items.every(function (item) {
            return ownerGids[item.metaAttributes?.owner ?? '']
        })
        setBulkLoading(false)
        if (hasRights) {
            setErrorMessage(undefined)
            return onSuccess()
        } else {
            setErrorMessage(t('tooltip.rights.missingPermission'))
            return onError()
        }
    }

    const confirmProjectApproved = async (
        uuid: string,
        nextState: string,
        handleResult: (actionResult: IBulkActionResult) => void,
        closePopup: () => void,
    ) => {
        setBulkLoading(true)
        await confirmProjectMutate(
            { uuid: uuid, nextState: nextState },
            {
                onSuccess: () => {
                    if (nextState === PROJECT_STATE_ENUM.c_stav_projektu_5) {
                        handleResult({ isSuccess: true, isError: false, successMessage: t('ciType.messages.successReturned') })
                    } else {
                        handleResult({ isSuccess: true, isError: false, successMessage: t('ciType.messages.success') })
                    }
                    closePopup()
                    queryClient.invalidateQueries(getReadConfigurationItemQueryKey(uuid))
                    queryClient.invalidateQueries(getGetNextStatesQueryKey(uuid))
                },
                onError: (error: ApiError) => {
                    const errorData = JSON.parse(error.message ?? '')
                    let m = 'ciType.messages.error'
                    if (errorData.message === 'not_created_documents') {
                        m = 'ciType.messages.errorDocuments'
                    } else if (errorData.message === 'not_created_relations') {
                        m = 'ciType.messages.errorRelations'
                    } else if (errorData.message === 'not_approved_ks') {
                        m = 'ciType.messages.errorNotApprovedKS'
                    } else if (errorData.message === 'not_allowed_approve') {
                        m = 'ciType.messages.errorNotAllowedApprove'
                    }
                    return handleResult({ isSuccess: false, isError: true, errorMessage: t(m) })
                },
                onSettled: () => setBulkLoading(false),
            },
        )
    }

    const handleConfirmProject = async (
        nextState: string,
        entityData: ConfigurationItemUi,
        handleResult: (actionResult: IBulkActionResult) => void,
        onError: () => void,
        closePopup: () => void,
    ) => {
        const projectStatus = entityData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_status]
        if (
            (projectStatus === PROJECT_STATE_ENUM.c_stav_projektu_4 || projectStatus == PROJECT_STATE_ENUM.c_stav_projektu_11) &&
            nextState === PROJECT_STATE_ENUM.c_stav_projektu_6
        ) {
            if (
                !entityData?.attributes?.[ATTRIBUTE_NAME.Financny_Profil_Projekt_schvalene_rocne_naklady] &&
                !entityData?.attributes?.[ATTRIBUTE_NAME.Financny_Profil_Projekt_schvaleny_rozpocet]
            ) {
                setErrorMessage(t('ciType.actions.error.approvedBudget'))
                return onError()
            } else {
                confirmProjectApproved(entityData?.uuid ?? '', nextState, handleResult, closePopup)
            }
        } else {
            confirmProjectApproved(entityData?.uuid ?? '', nextState, handleResult, closePopup)
        }
    }

    const handleProjectReturn = async (entityId: string, handleResult: (actionResult: IBulkActionResult) => void, closePopup: () => void) => {
        setBulkLoading(true)
        await mutateReturnProject(
            { uuid: entityId },
            {
                onSuccess: () => {
                    handleResult({ isSuccess: true, isError: false, successMessage: t('ciType.messages.successProjectReturn') })
                    closePopup()
                    queryClient.invalidateQueries(getReadConfigurationItemQueryKey(entityId))
                    queryClient.invalidateQueries(getGetNextStatesQueryKey(entityId))
                },
                onError: () => {
                    handleResult({ isSuccess: false, isError: true, errorMessage: t('ciType.messages.error') })
                },
                onSettled: () => setBulkLoading(false),
            },
        )
    }

    const handleAddToFavorite = async (ids: string[], type: FollowedItemItemType, onSuccess: (result: IBulkActionResult) => void) => {
        setBulkLoading(true)
        addFavoriteAsync(ids, type)
            .then(() => {
                return onSuccess({
                    isSuccess: true,
                    isError: false,
                    successMessage: t('userProfile.notifications.feedback.addSuccess', { count: ids.length }),
                })
            })
            .catch(() => {
                return onSuccess({ isSuccess: false, isError: true, errorMessage: t('feedback.queryErrorTitle') })
            })
            .finally(() => {
                setBulkLoading(false)
            })
    }

    return {
        errorMessage,
        isBulkLoading,
        handleInvalidate,
        handleReInvalidate,
        handleChangeOwner,
        handleDeleteFile,
        handleUpdateFile,
        handleConfirmProject,
        handleProjectReturn,
        handleAddToFavorite,
    }
}
