import { AbilityBuilder, createMongoAbility } from '@casl/ability'

import { Roles } from './useRequestPermissions'

import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { getHasIntRole } from '@isdd/metais-common/componentHelpers/ci/integration'
import {
    ADD_REQUEST_ITEM,
    CODE_LISTS,
    DOCUMENTS,
    ENTITY_INTEGRATION,
    ENTITY_REQUEST_LIST,
    OLA_Kontrakt,
    REFID_URI_DEF,
    REFID_URI_SZ,
    SLA_SPRAVA,
    STANDARDIZATION_DRAFTS_LIST,
} from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { canCreateCiForType, canUserCreateCi } from '@isdd/metais-common/permissions/ci'
import { Reference_Identifiers, Reference_Registers } from '@isdd/metais-common/api'

export enum Actions {
    READ = 'read',
    EXPORT = 'export',
    IMPORT = 'import',
    SELECT_COLUMNS = 'selectColumns',
    CREATE = 'create',
    EDIT = 'edit',
    CHANGE_OWNER = 'change_owner',
    HISTORY = 'history',
    BULK_ACTIONS = 'bulk_actions',
    CHANGE_STATES = 'change_states',
    HAS_ROLE = 'has_role',
    DELETE = 'DELETE',
    APPROVE_KRIS = 'APPROVE_KRIS',
    KRIS_SUBSCRIBE = 'KRIS_PODPIS',
    KRIS_SEND_APPROVING = 'KRIS_SEND_APPROVING',
    READ_TRAININGS = 'READ_TRAININGS',
    CHANGE_VALIDITY = 'CHANGE_VALIDITY',
    ACTIVITIES = 'activities',
    PUBLISH = 'publish',
}

export const ADMIN = 'R_ADMIN'
export const RR_MANAGER = 'RR_MANAGER'
export const RR_ADMIN_MFSR = 'RR_ADMIN_MFSR'
export const SKOLITEL = 'SKOLITEL'

export const CANNOT_READ_ENTITY = ['ulohy', 'notifications', 'data-objects/requestlist', 'public-authorities-hierarchy', 'userprofile']
export const CAN_CREATE_WITHOUT_LOGIN = [STANDARDIZATION_DRAFTS_LIST, RouterRoutes.STANDARDIZATION_DRAFTS_CREATE]

const defineAbilityForUser = (roles: string[] = [], entityName: string, create?: boolean) => {
    const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

    if (roles.includes(ADMIN)) {
        can(Actions.READ, entityName)
        can(Actions.CREATE, entityName)
        can(Actions.EXPORT, entityName)
        can(Actions.IMPORT, entityName)
        can(Actions.SELECT_COLUMNS, entityName)
        can(Actions.BULK_ACTIONS, entityName)
        can(Actions.HISTORY, entityName)
        can(Actions.PUBLISH, entityName)
    } else if (roles?.length > 0) {
        can(Actions.READ, entityName)
        if (create) can(Actions.CREATE, entityName)
        can(Actions.EXPORT, entityName)
        can(Actions.IMPORT, entityName)
        can(Actions.SELECT_COLUMNS, entityName)
        can(Actions.BULK_ACTIONS, entityName)
        can(Actions.HISTORY, entityName)
        can(Actions.PUBLISH, entityName)
    }

    if (roles.includes(SKOLITEL)) {
        can(Actions.READ_TRAININGS, entityName)
    }

    if (entityName === Reference_Registers && roles.includes(RR_MANAGER)) {
        can(Actions.CREATE, entityName)
    }

    if (entityName === Reference_Identifiers && (roles.includes(REFID_URI_SZ) || roles.includes(REFID_URI_DEF))) {
        can(Actions.CREATE, entityName)
    }

    if (entityName === STANDARDIZATION_DRAFTS_LIST) can(Actions.CREATE, STANDARDIZATION_DRAFTS_LIST)
    if (entityName == ENTITY_INTEGRATION) {
        const hasIntRole = getHasIntRole(roles)
        if (!hasIntRole) {
            cannot(Actions.CREATE, entityName)
        }
    }

    if (!!roles.length && entityName === DOCUMENTS) {
        can(Actions.CREATE, entityName)
    }

    if (entityName === ADD_REQUEST_ITEM && roles.includes(Roles.SZCHLGES)) {
        can(Actions.CREATE, entityName)
    }

    if (entityName == ENTITY_REQUEST_LIST || entityName == CODE_LISTS) {
        const hasRole = roles?.some((role) => role === Roles.SZCHLGES || role === Roles.SZCVEDGES)
        if (hasRole) {
            can(Actions.CREATE, entityName)
        }
    }

    if (entityName === OLA_Kontrakt) {
        if (roles.includes(SLA_SPRAVA)) can(Actions.CREATE, OLA_Kontrakt)
    }

    return build()
}

export const useUserAbility = (entityName?: string) => {
    const {
        state: { user },
    } = useAuth()

    return defineAbilityForUser(user?.roles, entityName ?? 'ci')
}

export const useCreateCiAbility = (technicalName: string, ciType?: CiType) => {
    const {
        state: { user },
    } = useAuth()

    if (ciType && canCreateCiForType(ciType)) {
        return defineAbilityForUser(user?.roles, technicalName ?? 'ci', canUserCreateCi(user ?? undefined, ciType?.roleList))
    }
    return defineAbilityForUser(user?.roles, technicalName ?? 'ci')
}
