import { ConfigurationItemUiAttributes, useStoreConfigurationItem } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType, useGetEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { CiCode, CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { ENTITY_KS } from '@isdd/metais-common/constants'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCiCreateEditOnStatusSuccess, useCiCreateUpdateOnSubmit } from './createEntityHelpers'
import { CreateFormularEntityForm } from './CreateFormularEntityForm'

import { useKSChannel } from '@/hooks/useChannelKS'
import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { useRolesForPO } from '@/hooks/useRolesForPO'

export interface AttributesData {
    ciTypeData: CiType | undefined
    constraintsData: (EnumType | undefined)[]
    unitsData?: EnumType
}

export interface CreateEntityData {
    attributesData: AttributesData
    generatedEntityId: CiCode | undefined
    ownerId?: string
    poUUID?: string
}

interface ICreateEntity {
    entityName: string
    data: CreateEntityData
    roleState?: RoleState
    publicAuthorityState?: PublicAuthorityState
    updateCiItemId?: string
    defaultItemAttributeValues?: ConfigurationItemUiAttributes
    isLoading: boolean
}

export const CreateFormularEntity: React.FC<ICreateEntity> = ({
    data,
    entityName,
    updateCiItemId,
    defaultItemAttributeValues,
    roleState,
    publicAuthorityState,
    isLoading,
}) => {
    const { t } = useTranslation()
    const isUpdate = !!updateCiItemId

    const { attributesData, generatedEntityId, ownerId, poUUID } = data
    const { constraintsData, ciTypeData, unitsData } = attributesData
    const onStatusSuccess = useCiCreateEditOnStatusSuccess()
    const { createChannelForKS, isLoading: isSubmitLoading, isError: isSubmitError } = useKSChannel()

    const { rolesForPO, isRightsForPOLoading } = useRolesForPO(
        updateCiItemId ? poUUID ?? '' : publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '',
        ciTypeData?.roleList ?? [],
    )
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()
    const { onSubmit, uploadError, setUploadError, configurationItemId, errorMessage } = useCiCreateUpdateOnSubmit(entityName, ciTypeData)
    const { data: eformTemplates, isLoading: isEformTemplatesLoading, isFetching: isEformTemplatesFetching } = useGetEnum('EFORM_TEMPLATES')
    const storeConfigurationItem = useStoreConfigurationItem({
        mutation: {
            onError() {
                setUploadError(true)
            },
            async onSuccess(successData, variables) {
                if (successData.requestId != null) {
                    await getRequestStatus(successData.requestId, async () => {
                        if (entityName === ENTITY_KS) {
                            await createChannelForKS({ ...variables.data, owner: variables.data.owner ?? ownerId }, () =>
                                onStatusSuccess({ configurationItemId, isUpdate, entityName }),
                            )
                        } else {
                            onStatusSuccess({ configurationItemId, isUpdate, entityName })
                        }
                    })
                } else {
                    setUploadError(true)
                }
            },
        },
    })

    return (
        <>
            <ElementToScrollTo
                trigger={isRedirectError || isProcessedError || isRedirectLoading || isTooManyFetchesError || uploadError}
                manualScroll
            >
                <MutationFeedback
                    error={uploadError}
                    errorMessage={errorMessage ? errorMessage : undefined}
                    mutationProcessingError={isProcessedError}
                    mutationTooLong={isTooManyFetchesError}
                />
            </ElementToScrollTo>

            <QueryFeedback
                loading={isRedirectLoading || isSubmitLoading}
                error={isRedirectError || isSubmitError}
                indicatorProps={{
                    label: isUpdate ? t('createEntity.redirectLoadingEdit') : t('createEntity.redirectLoading'),
                }}
                errorProps={{
                    errorMessage: isUpdate ? t('createEntity.redirectErrorEdit') : t('createEntity.redirectError'),
                }}
                withChildren
            >
                {!isUpdate && publicAuthorityState && roleState && (
                    <SelectPublicAuthorityAndRole
                        selectedRole={roleState.selectedRole ?? {}}
                        onChangeAuthority={publicAuthorityState.setSelectedPublicAuthority}
                        onChangeRole={roleState.setSelectedRole}
                        selectedOrg={publicAuthorityState.selectedPublicAuthority}
                        ciRoles={ciTypeData?.roleList ?? []}
                    />
                )}
                <QueryFeedback loading={isEformTemplatesFetching || isEformTemplatesLoading} withChildren>
                    <CreateFormularEntityForm
                        entityName={entityName}
                        ciTypeData={ciTypeData}
                        generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                        constraintsData={constraintsData}
                        unitsData={unitsData}
                        uploadError={uploadError}
                        onSubmit={(formData) =>
                            onSubmit({
                                formData,
                                updateCiItemId,
                                storeCiItem: storeConfigurationItem.mutateAsync,
                                ownerId: ownerId,
                                generatedEntityId,
                                publicAuthorityState,
                            })
                        }
                        defaultItemAttributeValues={defaultItemAttributeValues}
                        updateCiItemId={updateCiItemId}
                        isProcessing={storeConfigurationItem.isLoading || isLoading || isRightsForPOLoading}
                        selectedOrg={publicAuthorityState?.selectedPublicAuthority}
                        selectedRole={roleState?.selectedRole ?? null}
                        rolesForPO={rolesForPO ?? []}
                        eformTemplates={eformTemplates?.enumItems ?? []}
                    />
                </QueryFeedback>
            </QueryFeedback>
        </>
    )
}
