import { Button, ButtonLink, CheckBox, Fieldset, IColumn, IColumnSectionType, IColumnType, TextBody } from '@isdd/idsk-ui-kit/index'
import { SearchInput } from '@isdd/idsk-ui-kit/searchInput'
import classNames from 'classnames'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/index'
import { ChangeEvent, useState } from 'react'
import { latinizeString } from '@isdd/metais-common/componentHelpers/filter/feFilters'
import { Attribute, CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useTranslation } from 'react-i18next'
import { getCiDefaultMetaAttributes } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'

import styles from './styles.module.scss'

export interface IColumnSectionProps {
    sectionName: string
    columns: IColumnType[]
    updateSelectedValue: (key: string, checked: boolean) => void
    getIsColumnChecked: (columnName: string) => boolean
}

const ColumnSection: React.FC<IColumnSectionProps> = ({ sectionName, columns, updateSelectedValue, getIsColumnChecked }) => {
    if (columns.length === 0) return null
    return (
        <Fieldset
            className={classNames(styles.fieldset, 'govuk-checkboxes govuk-checkboxes--small')}
            legend={
                <TextBody size="S" className={classNames('govuk-!-font-weight-bold', styles.textHeader)}>
                    {sectionName}
                </TextBody>
            }
        >
            {columns.map((column) => {
                return (
                    <CheckBox
                        labelClassName={styles.customLabelCheckbox}
                        key={column.technicalName}
                        label={column.name}
                        name={column.technicalName}
                        value={column.technicalName}
                        checked={getIsColumnChecked(column.technicalName)}
                        onChange={(e) => updateSelectedValue(e.target.value, e.target.checked)}
                        disabled={column.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov}
                    />
                )
            })}
        </Fieldset>
    )
}

interface ICiTableSelectColumnsProps {
    columnListData?: IColumn | undefined
    ciTypeData?: CiType
    onColumnsSelect: (selectedColumns: Attribute[], selectedMetaColumns: Attribute[]) => void
    onClose: () => void
    resetToDefault: () => void
}

export const CiTableColumnsSelectForModal: React.FC<ICiTableSelectColumnsProps> = ({
    columnListData,
    ciTypeData,
    onColumnsSelect,
    onClose,
    resetToDefault,
}) => {
    const { t } = useTranslation()

    const [selectedColumns, setSelectedColumns] = useState<Attribute[]>(columnListData?.attributes || [])
    const [selectedMetaColumns, setSelectedMetaColumns] = useState<Attribute[]>(columnListData?.metaAttributes || [])
    const [search, setSearch] = useState('')

    const attributeProfilesColumnSections: IColumnSectionType[] =
        ciTypeData?.attributeProfiles?.map((attributeProfile) => ({
            name: attributeProfile.name || '',
            attributes:
                attributeProfile.attributes
                    ?.filter((attribute) => attribute.invisible === false && attribute.valid)
                    .map((attribute) => ({
                        name: attribute.name || '',
                        technicalName: attribute.technicalName || '',
                    })) || [],
        })) ?? []

    const attributesColumnSection: IColumnSectionType = {
        name: 'insertCiName',
        attributes:
            ciTypeData?.attributes
                ?.filter((attribute) => attribute.invisible === false && attribute.valid)
                ?.map((attribute) => ({
                    name: attribute.name || '',
                    technicalName: attribute.technicalName || '',
                })) ?? [],
    }

    const metaAttributesColumnSection = getCiDefaultMetaAttributes({ t, entityName: ciTypeData?.name })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const filterColumnsByNameSearch = (columns: IColumnType[]): IColumnType[] => {
        return columns.filter((column) => latinizeString(column.name).includes(latinizeString(search)))
    }

    const saveSelection = () => {
        onColumnsSelect(selectedColumns, selectedMetaColumns)
    }

    const updateSelectedValue = (key: string, checked: boolean) => {
        setSelectedColumns((prev) => {
            const selectedPrev = prev.filter((column) => column.name !== key)
            if (checked) {
                return [...selectedPrev, { name: key, order: prev.length + 1 }]
            }
            return selectedPrev
        })
    }

    const updateMetaSelectedValue = (key: string, checked: boolean) => {
        setSelectedMetaColumns((prev) => {
            const selectedPrev = prev.filter((column) => column.name !== key)
            if (checked) {
                return [...selectedPrev, { name: key, order: prev.length + 1 }]
            }
            return selectedPrev
        })
    }

    const getIsColumnChecked = (columnName: string) => {
        return selectedColumns.some((attr) => columnName === attr.name)
    }

    const getIsMetaColumnChecked = (columnName: string) => {
        return selectedMetaColumns.some((attr) => columnName === attr.name)
    }

    return (
        <>
            <div>
                <SearchInput id="search" name="search" className={styles.searchbar} onChange={handleChange} isColumnSearch />
                <div className={styles.scroll}>
                    <ColumnSection
                        sectionName={attributesColumnSection?.name ?? ''}
                        columns={filterColumnsByNameSearch(attributesColumnSection?.attributes ?? [])}
                        updateSelectedValue={updateSelectedValue}
                        getIsColumnChecked={getIsColumnChecked}
                    />

                    {attributeProfilesColumnSections?.map((section) => {
                        const filteredAttributes = filterColumnsByNameSearch(section.attributes)
                        return (
                            <ColumnSection
                                key={section.name}
                                sectionName={section.name}
                                columns={filteredAttributes}
                                updateSelectedValue={updateSelectedValue}
                                getIsColumnChecked={getIsColumnChecked}
                            />
                        )
                    })}
                    <ColumnSection
                        sectionName={metaAttributesColumnSection?.name ?? ''}
                        columns={filterColumnsByNameSearch(metaAttributesColumnSection?.attributes ?? [])}
                        updateSelectedValue={updateMetaSelectedValue}
                        getIsColumnChecked={getIsMetaColumnChecked}
                    />
                </div>
                <div className={styles.buttonGroup}>
                    <ButtonLink
                        label={t('tableSelectColumns.refreshButton')}
                        onClick={() => {
                            resetToDefault()
                            onClose()
                        }}
                        className={styles.resetDefaultOrderButton}
                    />
                    <ButtonLink label={t('tableSelectColumns.cancelButton')} onClick={onClose} className={styles.cancelButton} />
                    <Button
                        label={t('tableSelectColumns.viewButton')}
                        onClick={() => {
                            saveSelection()
                            onClose()
                        }}
                        className={styles.viewButton}
                    />
                </div>
            </div>
        </>
    )
}
