import { ISection, IStepLabel } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import { ATTRIBUTE_NAME, Gen_Profil } from '@isdd/metais-common/api/constants'
import { ConfigurationItemUiAttributes, HierarchyRightsUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumItem, EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { GidRoleData, useFindByNameWithParams, useFindByNameWithParamsCount } from '@isdd/metais-common/api/generated/iam-swagger'
import { AttributeProfile, CiCode, CiType, RelationshipCode, RelationshipType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { Languages } from '@isdd/metais-common/localization/languages'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FieldValues, FieldErrors, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BASE_PAGE_SIZE } from '@isdd/metais-common/constants'
import { SectionWarning } from '@isdd/idsk-ui-kit/stepper/SectionWarning'
import { Button, SelectLazyLoading } from '@isdd/idsk-ui-kit/index'
import { Stepper } from '@isdd/idsk-ui-kit/stepper/Stepper'
import { SubmitWithFeedback } from '@isdd/metais-common/index'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { includes } from 'lodash'

import { getFilteredAttributeProfilesBasedOnRole, getValidAndVisibleAttributes } from './createEntityHelpers'
import { CreateEntitySection } from './CreateEntitySection'
import { generateFormSchema } from './createCiEntityFormSchema'
import styles from './createEntity.module.scss'

import { AttributesConfigTechNames } from '@/components/attribute-input/attributeDisplaySettings'
import { filterFormValuesBasedOnCurrentRole, formatForFormDefaultValues } from '@/componentHelpers/ci'

export interface HasResetState {
    hasReset: boolean
    setHasReset: Dispatch<SetStateAction<boolean>>
}
interface ICreateCiEntityForm {
    entityName: string
    generatedEntityId: CiCode
    constraintsData: (EnumType | undefined)[]
    ciTypeData: CiType | undefined
    uploadError: boolean
    onSubmit: (formData: FieldValues) => void
    unitsData: EnumType | undefined
    defaultItemAttributeValues?: ConfigurationItemUiAttributes | undefined
    updateCiItemId?: string
    relationSchema?: RelationshipType
    isProcessing?: boolean
    withRelation?: boolean
    selectedRole?: GidRoleData | null
    selectedOrg?: HierarchyRightsUi | null
    generatedRelCode?: RelationshipCode
    rolesForPO: GidRoleData[]
    eformTemplates: EnumItem[]
}

export const CreateFormularEntityForm: React.FC<ICreateCiEntityForm> = ({
    entityName,
    ciTypeData,
    unitsData,
    constraintsData,
    generatedEntityId,
    onSubmit,
    defaultItemAttributeValues,
    updateCiItemId,
    relationSchema,
    isProcessing,
    withRelation,
    selectedRole,
    rolesForPO,
    eformTemplates,
}) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [hasReset, setHasReset] = useState(false)
    const [sections, setSections] = useState<ISection[]>([])

    const ability = useAbilityContext()
    const canCreateRelationType = ability?.can(Actions.CREATE, `ci.create.newRelationType`)
    const isUpdate = !!updateCiItemId
    const isSubmitDisabled = (!selectedRole?.roleUuid && !updateCiItemId) || (withRelation ? !canCreateRelationType : false)

    const attProfiles = useMemo(() => ciTypeData?.attributeProfiles?.map((profile) => profile) ?? [], [ciTypeData?.attributeProfiles])
    const attProfileTechNames = useMemo(() => attProfiles.map((profile) => profile.technicalName), [attProfiles])
    const mappedProfileTechNames: Record<string, boolean> = useMemo(
        () =>
            attProfileTechNames.reduce<Record<string, boolean>>((accumulator, attributeName) => {
                if (attributeName != null) {
                    accumulator[attributeName] = false
                }
                return accumulator
            }, {}),
        [attProfileTechNames],
    )

    const attributes = useMemo(() => getValidAndVisibleAttributes(ciTypeData), [ciTypeData])
    const count = useFindByNameWithParamsCount({ name: '', system: 'All', group: 'All' })

    const roles = useFindByNameWithParams(
        1,
        count.data ?? BASE_PAGE_SIZE,
        { system: 'All', group: 'All', orderBy: 'name', direction: 'asc', name: '' },
        { query: { enabled: !!count.data } },
    )

    const getRoleNames = (roleTechNames: string[]): (string | undefined)[] => {
        return roleTechNames.map((roleTechName) => {
            return roles.data?.find((role) => role.name === roleTechName)?.description
        })
    }

    const defaultValuesFromSchema = useMemo(() => {
        return attributes.reduce((acc, att) => {
            if (att?.defaultValue) {
                return { ...acc, [att?.technicalName?.toString() ?? '']: att?.defaultValue }
            }
            return acc
        }, {})
    }, [attributes])

    const formDefaultValues = useMemo(
        () =>
            formatForFormDefaultValues(
                isUpdate ? defaultItemAttributeValues ?? {} : (defaultValuesFromSchema as ConfigurationItemUiAttributes) ?? {},
                attributes,
            ),
        [isUpdate, attributes, defaultItemAttributeValues, defaultValuesFromSchema],
    )

    const sectionErrorDefaultConfig: { [x: string]: boolean } = useMemo(
        () => ({
            [Gen_Profil]: false,
            ...mappedProfileTechNames,
        }),
        [mappedProfileTechNames],
    )
    const canMainInfoSection = ciTypeData?.roleList?.some((sectionRole) => rolesForPO?.map((role) => role.roleName).includes(sectionRole))
    const [sectionError, setSectionError] = useState<{ [x: string]: boolean }>(sectionErrorDefaultConfig)

    const genProfilNazovAttr = ciTypeData?.attributes?.find((attr) => attr.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov)

    const combinedProfiles = useMemo(() => [ciTypeData as AttributeProfile, ...(ciTypeData?.attributeProfiles ?? [])], [ciTypeData])
    const formSchema = useMemo(() => {
        return generateFormSchema(
            isUpdate ? combinedProfiles : getFilteredAttributeProfilesBasedOnRole(combinedProfiles, selectedRole?.roleName ?? ''),
            t,
            i18n.language,
            null,
            ciTypeData?.technicalName,
            formDefaultValues,
        )
    }, [isUpdate, combinedProfiles, selectedRole?.roleName, t, i18n.language, ciTypeData?.technicalName, formDefaultValues])

    const methods = useForm({
        defaultValues: formDefaultValues as { [x: string]: object | undefined },
        mode: 'onChange',
        resolver: yupResolver(formSchema),
    })
    const { handleSubmit, reset, formState, getValues, setValue, trigger, watch } = methods
    const genProfilNazovInput: string = watch(ATTRIBUTE_NAME.Gen_Profil_nazov) as string

    const loadOptions = async (searchQuery: string, additional: { page: number } | undefined) => {
        const perPage = 100

        const page = !additional?.page ? 1 : (additional?.page || 0) + 1
        let result = []

        if (searchQuery && searchQuery.length > 0) {
            result =
                eformTemplates.filter(
                    (e) => includes(e.code?.toLowerCase(), searchQuery.toLowerCase()) || includes(e.value?.toLowerCase(), searchQuery.toLowerCase()),
                ) ?? []
        } else {
            result = eformTemplates
        }

        const totalPages = result.length / perPage
        return {
            options: result.slice((page - 1) * perPage, perPage * page),
            hasMore: totalPages > page,
            additional: {
                page,
            },
        }
    }

    const [seed, setSeed] = useState<number>(1)

    useEffect(() => {
        setSeed((prev) => prev + 1)
    }, [defaultItemAttributeValues, eformTemplates, genProfilNazovInput])

    useEffect(() => {
        const result: ISection[] = [
            {
                title: t('ciInformationAccordion.basicInformation'),
                sectionWarning: (
                    <>
                        {!isProcessing && !roles.isLoading && !canMainInfoSection && (
                            <SectionWarning>{`${t('ciType.section.noRights', {
                                roles: getRoleNames(ciTypeData?.roleList ?? [])?.join(', '),
                            })}`}</SectionWarning>
                        )}
                    </>
                ),
                stepLabel: { label: '1', variant: 'circle' },
                error: sectionError[Gen_Profil] === true,
                id: Gen_Profil,
                isOpen: sections.find((item) => item.id === Gen_Profil)?.isOpen,
                attributes: ciTypeData?.attributes,
                content: (
                    <>
                        <SelectLazyLoading<EnumItem>
                            key={seed}
                            label={`${i18n.language === Languages.SLOVAK ? genProfilNazovAttr?.name : genProfilNazovAttr?.engName}`}
                            loadOptions={(searchTerm, _, additional) => loadOptions(searchTerm, additional)}
                            getOptionLabel={(item: EnumItem) => `${item?.code} - ${item?.value}`}
                            getOptionValue={(item: EnumItem) => item.value ?? ''}
                            name={ATTRIBUTE_NAME.Gen_Profil_nazov}
                            defaultValue={eformTemplates?.find(
                                (e) => e.code === defaultItemAttributeValues?.[ATTRIBUTE_NAME.EA_Profil_Formular_kod_formulara],
                            )}
                            onChange={(val) => {
                                const enumItem = val as EnumItem
                                setValue(ATTRIBUTE_NAME.Gen_Profil_nazov, enumItem?.value)
                                setValue(ATTRIBUTE_NAME.EA_Profil_Formular_kod_formulara, enumItem?.code)
                            }}
                        />
                        <CreateEntitySection
                            entityName={entityName}
                            sectionId={Gen_Profil}
                            attributes={
                                ciTypeData?.attributes
                                    ?.filter((attr) => attr.technicalName !== ATTRIBUTE_NAME.Gen_Profil_nazov)
                                    ?.sort((a, b) => (a.order ?? -1) - (b.order ?? -1)) ?? []
                            }
                            constraintsData={constraintsData}
                            unitsData={unitsData}
                            generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                            defaultItemAttributeValues={defaultItemAttributeValues}
                            hasResetState={{ hasReset, setHasReset }}
                            updateCiItemId={updateCiItemId}
                            setSectionError={setSectionError}
                            sectionRoles={ciTypeData?.roleList ?? []}
                            selectedRole={selectedRole}
                            rolesForPO={rolesForPO}
                        />
                    </>
                ),
            },
            ...attProfiles.map((profile, index) => {
                const canEditSection = profile.roleList?.some((sectionRole) => rolesForPO?.map((role) => role.roleName).includes(sectionRole))

                return {
                    title: (i18n.language === Languages.SLOVAK ? profile.description || profile.name : profile.engDescription || profile.name) || '',
                    sectionWarning: (
                        <>
                            {!isProcessing && !canEditSection && (
                                <SectionWarning>{`${t('ciType.section.noRights', {
                                    roles: getRoleNames(profile.roleList ?? [])?.join(', '),
                                })}`}</SectionWarning>
                            )}
                        </>
                    ),
                    stepLabel: { label: (index + 2).toString(), variant: 'circle' } as IStepLabel,
                    last: relationSchema ? false : attProfiles.length === index + 1 ? true : false,
                    id: `${profile.technicalName}`,
                    isOpen: sections.find((item) => item.id === `${profile.technicalName}`)?.isOpen,
                    attributes: profile.attributes,
                    error: sectionError[profile.technicalName ?? ''] === true,
                    content: (
                        <>
                            <CreateEntitySection
                                entityName={entityName}
                                sectionId={profile.technicalName ?? ''}
                                attributes={
                                    profile.attributes
                                        ?.map((attr) => {
                                            if (attr.technicalName === ATTRIBUTE_NAME.EA_Profil_Formular_kod_formulara) {
                                                return { ...attr, constraints: [] }
                                            } else {
                                                return attr
                                            }
                                        })
                                        ?.sort((a, b) => (a.order ?? -1) - (b.order ?? -1)) ?? []
                                }
                                constraintsData={constraintsData}
                                generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                                unitsData={unitsData}
                                setSectionError={setSectionError}
                                defaultItemAttributeValues={defaultItemAttributeValues}
                                hasResetState={{ hasReset, setHasReset }}
                                updateCiItemId={updateCiItemId}
                                sectionRoles={profile.roleList ?? []}
                                selectedRole={selectedRole}
                                rolesForPO={rolesForPO}
                            />
                        </>
                    ),
                }
            }),
        ]

        setSections(result)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        attProfiles,
        ciTypeData?.attributes,
        ciTypeData?.roleList,
        constraintsData,
        defaultItemAttributeValues,
        generatedEntityId,
        hasReset,
        i18n.language,
        mappedProfileTechNames,
        relationSchema,
        sectionError,
        selectedRole,
        t,
        entityName,
        unitsData,
        updateCiItemId,
        eformTemplates,
    ])

    const handleSectionOpen = (id: string) => {
        setSections((prev) => prev.map((item) => (item.id === id ? { ...item, isOpen: !item.isOpen } : item)))
    }

    const openOrCloseAllSections = () => {
        setSections((prev) => {
            const allOpen = prev.every((item) => item.isOpen)
            return prev.map((item) => ({ ...item, isOpen: !allOpen }))
        })
    }

    const handleSectionBasedOnError = (errors: FieldErrors) => {
        setSections((prev) =>
            prev.map((section) => {
                const isSectionError = !!Object.keys(errors).find((item) => section.attributes?.find((attribute) => attribute.technicalName === item))
                if (isSectionError) {
                    return { ...section, isOpen: true, error: true }
                }
                return { ...section, error: false }
            }),
        )
    }

    useEffect(() => {
        formState.isSubmitted && trigger()
    }, [formState.isSubmitted, t, trigger])
    useEffect(() => {
        if (!isUpdate) {
            const currentValues = getValues()
            const currentRole = selectedRole
            const attrs = getValidAndVisibleAttributes(ciTypeData)

            const filteredFormValuesWithoutPermission = filterFormValuesBasedOnCurrentRole(
                combinedProfiles,
                currentRole?.roleName ?? '',
                currentValues,
            )

            reset(formatForFormDefaultValues(filteredFormValuesWithoutPermission, attrs))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRole])

    useEffect(() => {
        const referenceIdValue = generatedEntityId?.ciurl?.split('/').pop()
        const metaIsCodeValue = generatedEntityId?.cicode
        setValue(AttributesConfigTechNames.REFERENCE_ID, referenceIdValue)
        setValue(AttributesConfigTechNames.METAIS_CODE, metaIsCodeValue)
        Object.entries(formDefaultValues).forEach((item) => {
            const element = formState.defaultValues?.[item[0]]
            if (element == '' || element == undefined) {
                setValue(item[0], item[1])
            }
        })
    }, [formState.defaultValues, formDefaultValues, setValue, generatedEntityId?.ciurl, generatedEntityId?.cicode])

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, (errors) => handleSectionBasedOnError(errors))} noValidate>
                    <Stepper
                        subtitleTitle=""
                        stepperList={sections}
                        handleSectionOpen={handleSectionOpen}
                        openOrCloseAllSections={openOrCloseAllSections}
                    />
                    <SubmitWithFeedback
                        className={styles.buttonGroup}
                        additionalButtons={[
                            <Button
                                key={1}
                                label={t('button.cancel')}
                                type="reset"
                                variant="secondary"
                                onClick={() => {
                                    reset()
                                    setHasReset(true)
                                    navigate(-1)
                                }}
                            />,
                        ]}
                        submitButtonLabel={t('button.saveChanges')}
                        loading={isProcessing || formState.isValidating}
                        disabled={isSubmitDisabled}
                    />
                </form>
            </FormProvider>
        </>
    )
}
